import './App.css';

function App() {

  return (
    <div style={{
      textAlign: 'center',
      color: 'yellow',
      fontSize: '30pt',
      fontWeight: 700,
      marginBottom: '20px'
  }}>
      Hát Vui Vẻ
  </div>
  );
}

export default App;
