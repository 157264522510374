import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    status: '',
    display: 'home_page_landing',
}

export const mainSlice = createSlice({
    name: 'main',
    initialState,
    reducers: {
        update_main_display_action: (state, action) => {
            state.display = action.payload
        },
        setAppStatusAction: (state, action) => {
            state.status = action.payload
        }
    },
})

export const {
    update_main_display_action,
    setAppStatusAction,
} = mainSlice.actions

export default mainSlice.reducer